@font-face {
  font-family: 'roboto_monoregular';
  src: url("./fonts/robotomono-variablefont_wght-webfont.woff2") format("woff2"), url("./fonts/robotomono-variablefont_wght-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'roboto_monoitalic';
  src: url("./fonts/robotomono-italic-variablefont_wght-webfont.woff2") format("woff2"), url("./fonts/robotomono-italic-variablefont_wght-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: roboto_monoregular;
}

:root {
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  background-color: white;
}

/*styles login canacope*/
.layout-login {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login {
  width: 370px;
}

.login__logo {
  text-align: center;
}

.login__logo-img {
  width: 200px;
  height: 56px;
  display: inline-block;
  margin-left: auto;
}

.login__input {
  width: 100%;
  height: 37px;
  background-color: #2c2c2c;
  border: none;
  padding-left: 50px;
  color: white;
  font-size: 1.1875em;
}

.login__input:focus {
  outline: 2px solid #194F6E;
}

.login__input:focus + .icon__input {
  fill: #194F6E;
}

.icon__input {
  position: absolute;
  left: 15px;
  top: 6px;
  width: 1.5em;
  height: 1.5em;
  fill: white;
  transition: fill 0.2s linear;
}

.header {
  padding: 0 30px;
}

.header::after {
  content: "";
  display: block;
  height: 3px;
  background-color: #194F6E;
  margin-top: 8px;
}

.header__content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__nav {
  display: inline-block;
}

.header__nav svg {
  width: 35px;
  height: 35px;
}

.header__nav svg:hover {
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .header__nav svg {
    width: 30px;
    height: 30px;
  }
}

.header__nav a {
  text-decoration: none;
  color: black;
}

.header__logo {
  width: 150px;
  height: 50px;
}

.margin-icon__left {
  margin-left: 30px;
}

.margin-icon__right {
  margin-right: 30px;
}

.margin-small-top-1 {
  margin-top: 5px;
}

.padding-small-top-1 {
  padding-top: 5px;
}

.margin-small-left-1 {
  margin-left: 5px;
}

.padding-small-left-1 {
  padding-left: 5px;
}

.margin-small-right-1 {
  margin-right: 5px;
}

.padding-small-right-1 {
  padding-right: 5px;
}

.margin-small-bottom-1 {
  margin-bottom: 5px;
}

.padding-small-bottom-1 {
  padding-bottom: 5px;
}

.margin-small-top-2 {
  margin-top: 10px;
}

.padding-small-top-2 {
  padding-top: 10px;
}

.margin-small-left-2 {
  margin-left: 10px;
}

.padding-small-left-2 {
  padding-left: 10px;
}

.margin-small-right-2 {
  margin-right: 10px;
}

.padding-small-right-2 {
  padding-right: 10px;
}

.margin-small-bottom-2 {
  margin-bottom: 10px;
}

.padding-small-bottom-2 {
  padding-bottom: 10px;
}

.margin-small-top-3 {
  margin-top: 15px;
}

.padding-small-top-3 {
  padding-top: 15px;
}

.margin-small-left-3 {
  margin-left: 15px;
}

.padding-small-left-3 {
  padding-left: 15px;
}

.margin-small-right-3 {
  margin-right: 15px;
}

.padding-small-right-3 {
  padding-right: 15px;
}

.margin-small-bottom-3 {
  margin-bottom: 15px;
}

.padding-small-bottom-3 {
  padding-bottom: 15px;
}

.margin-medium-top-1 {
  margin-top: 20px;
}

.padding-medium-top-1 {
  padding-top: 20px;
}

.margin-medium-left-1 {
  margin-left: 20px;
}

.padding-medium-left-1 {
  padding-left: 20px;
}

.margin-medium-right-1 {
  margin-right: 20px;
}

.padding-medium-right-1 {
  padding-right: 20px;
}

.margin-medium-bottom-1 {
  margin-bottom: 20px;
}

.padding-medium-bottom-1 {
  padding-bottom: 20px;
}

.margin-medium-top-2 {
  margin-top: 25px;
}

.padding-medium-top-2 {
  padding-top: 25px;
}

.margin-medium-left-2 {
  margin-left: 25px;
}

.padding-medium-left-2 {
  padding-left: 25px;
}

.margin-medium-right-2 {
  margin-right: 25px;
}

.padding-medium-right-2 {
  padding-right: 25px;
}

.margin-medium-bottom-2 {
  margin-bottom: 25px;
}

.padding-medium-bottom-2 {
  padding-bottom: 25px;
}

.margin-medium-top-3 {
  margin-top: 30px;
}

.padding-medium-top-3 {
  padding-top: 30px;
}

.margin-medium-left-3 {
  margin-left: 30px;
}

.padding-medium-left-3 {
  padding-left: 30px;
}

.margin-medium-right-3 {
  margin-right: 30px;
}

.padding-medium-right-3 {
  padding-right: 30px;
}

.margin-medium-bottom-3 {
  margin-bottom: 30px;
}

.padding-medium-bottom-3 {
  padding-bottom: 30px;
}

.margin-large-top-1 {
  margin-top: 35px;
}

.padding-large-top-1 {
  padding-top: 35px;
}

.margin-large-left-1 {
  margin-left: 35px;
}

.padding-large-left-1 {
  padding-left: 35px;
}

.margin-large-right-1 {
  margin-right: 35px;
}

.padding-large-right-1 {
  padding-right: 35px;
}

.margin-large-bottom-1 {
  margin-bottom: 35px;
}

.padding-large-bottom-1 {
  padding-bottom: 35px;
}

.margin-large-top-2 {
  margin-top: 40px;
}

.padding-large-top-2 {
  padding-top: 40px;
}

.margin-large-left-2 {
  margin-left: 40px;
}

.padding-large-left-2 {
  padding-left: 40px;
}

.margin-large-right-2 {
  margin-right: 40px;
}

.padding-large-right-2 {
  padding-right: 40px;
}

.margin-large-bottom-2 {
  margin-bottom: 40px;
}

.padding-large-bottom-2 {
  padding-bottom: 40px;
}

.margin-large-top-3 {
  margin-top: 45px;
}

.padding-large-top-3 {
  padding-top: 45px;
}

.margin-large-left-3 {
  margin-left: 45px;
}

.padding-large-left-3 {
  padding-left: 45px;
}

.margin-large-right-3 {
  margin-right: 45px;
}

.padding-large-right-3 {
  padding-right: 45px;
}

.margin-large-bottom-3 {
  margin-bottom: 45px;
}

.padding-large-bottom-3 {
  padding-bottom: 45px;
}

.margin-large-top-4 {
  margin-top: 50px;
}

.padding-large-top-4 {
  padding-top: 50px;
}

.margin-large-left-4 {
  margin-left: 50px;
}

.padding-large-left-4 {
  padding-left: 50px;
}

.margin-large-right-4 {
  margin-right: 50px;
}

.padding-large-right-4 {
  padding-right: 50px;
}

.margin-large-bottom-4 {
  margin-bottom: 50px;
}

.padding-large-bottom-4 {
  padding-bottom: 50px;
}

.margin-auto {
  margin: auto;
}

.button {
  padding: 5px 20px;
  text-transform: capitalize;
  border: none;
  color: white !important;
  font-family: roboto_monoregular;
  font-weight: 700;
  text-decoration: none;
}

.button:hover {
  cursor: pointer;
}

.button__primary {
  background-color: #194F6E;
}

.button__secundary {
  background-color: #2c2c2c;
}

.button__danger {
  background-color: #CB4335;
}

.button__active {
  background-color: #17A589;
}

.button--size-small {
  font-size: 0.8125em;
}

.button--size-medium {
  font-size: 1em;
}

.button--size-large {
  font-size: 1.125em;
}

.button__disabled {
  background-color: darkgray;
}

.layout-dashboard {
  display: grid;
  min-height: 100vh;
  grid-template-columns: 200px 1fr;
  grid-template-rows: max-content 1fr;
  grid-template-areas: "header header" "aside main";
}

.layout-dashboard__header {
  grid-area: header;
}

.layout-dashboard__aside {
  grid-area: aside;
}

.layout-dashboard__main {
  grid-area: main;
  padding-left: 10px;
  padding-right: 10px;
}

.filtro__content {
  border-right: 3px solid #194F6E;
  width: 80px;
  left: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.filtro__content svg {
  width: 30px;
  height: 30px;
}

.filtro__content svg:hover {
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .filtro__content svg {
    width: 25px;
    height: 25px;
  }
}

.filtro__content .filter__element {
  font-weight: 700;
  font-size: 1.5em;
}

.filtro__content .filter__element:hover {
  cursor: pointer;
}

.filtro--position {
  position: fixed;
  width: 200px;
  height: calc(100vh - 70px);
  top: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.form__input, .form__checkbox, .form__text-area {
  width: 100%;
  background-color: #C4C4C4;
  padding: 0 4px;
  text-transform: uppercase;
}

.form__input::placeholder, .form__checkbox::placeholder, .form__text-area::placeholder {
  color: #454545;
}

.form__icon, .form__label {
  display: flex;
  align-items: center;
}

.content-element__form-sample {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 20px;
}

.content-element__form-description {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 20px;
  grid-template-rows: auto auto;
}

.content-element__form-checkbox {
  display: grid;
  grid-template-columns: 30px max-content;
  column-gap: 20px;
}

.form__input {
  height: 30px;
  border: none;
  grid-column: 2/3;
  font-size: 16px;
}

.form__checkbox {
  height: 30px;
  border: none;
  grid-column: 1/2;
}

.form__checkbox input:checked + .checkmark::after {
  visibility: visible;
}

.form__checkbox:hover {
  cursor: pointer;
}

.checkmark {
  width: 100%;
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-content: center;
}

.checkmark::after {
  content: "*";
  visibility: hidden;
  font-size: 1.625em;
  justify-content: center;
  align-items: center;
}

.form__icon {
  grid-column: 1/2;
}

.form__description {
  grid-column: 1/3;
  text-align: center;
}

.icon-form {
  width: 26px;
  height: 26px;
}

.form__label {
  font-weight: 700;
}

.layout__grid-map {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 40px;
}

.form__container {
  width: 250px;
  margin: 130px auto 0 auto;
  text-align: center;
}

.form__normal {
  outline: 1px solid #194F6E;
}

.form__danger {
  outline: 2px solid #CB4335;
}

.animacion-search {
  animation-name: opacidad;
  animation-timing-function: linear;
  animation-duration: 0.3s;
}

@keyframes opacidad {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.form__disabled *, .form__disabled *::placeholder {
  color: darkgray;
}

.form__disabled .color-title__primary {
  color: darkgray;
}

.form__disabled input, .form__disabled select, .form__disabled label {
  outline-color: darkgray;
}

.form__disabled button {
  background-color: darkgray;
}

.element-form__disable {
  color: darkgray;
}

.element-form__disable + input {
  outline-color: darkgray;
}

.form__map-container {
  width: 70%;
  margin: 130px auto 0 auto;
  text-align: center;
}

.form__large-element {
  width: 50%;
  margin: 130px auto 0 auto;
}

.form__input--small {
  width: 50px !important;
}

.form__input--medium {
  width: 100px !important;
}

.form__uppercase {
  text-transform: uppercase;
}

.form__text-area {
  border: none;
  font-size: 16px;
}

.titles, .subtitles {
  font-weight: 900;
}

.titles::after, .subtitles::after {
  content: "";
  display: block;
  height: 3px;
  width: 350px;
  background-color: #194F6E;
  margin-top: 3px;
}

.titles {
  font-size: 1.25em;
}

.subtitles {
  font-size: 1em;
}

.color-title__primary {
  color: #194F6E;
  font-size: 20px;
}

.grid-layout {
  display: grid;
}

.grid-1 {
  grid-template-columns: repeat(1, 1fr);
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid-6 {
  grid-template-columns: repeat(6, 1fr);
}

.grid-7 {
  grid-template-columns: repeat(7, 1fr);
}

.grid-8 {
  grid-template-columns: repeat(8, 1fr);
}

.grid-9 {
  grid-template-columns: repeat(9, 1fr);
}

.grid-10 {
  grid-template-columns: repeat(10, 1fr);
}

.grid-11 {
  grid-template-columns: repeat(11, 1fr);
}

.grid-12 {
  grid-template-columns: repeat(12, 1fr);
}

.grip-gap {
  column-gap: 20px;
  row-gap: 20px;
}

.enabled {
  display: block;
}

.disabled {
  display: none !important;
}

.flex {
  display: flex;
}

.visible-hidden {
  visibility: hidden;
}

.visible-element {
  visibility: visible;
}

.modal__size--small, .modal__size--medium, .modal__size--large {
  background-color: white;
  border: 3px solid #194F6E;
  margin: auto;
  overflow: hidden;
  transform: translateY(-200px);
  opacity: 0;
  transition-property: opacity, transform;
  transition: 0.3s linear;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  transition: opacity .30s linear;
  align-items: center;
}

.modal__size--small {
  width: 25%;
}

.modal__size--medium {
  width: 50%;
}

.modal__size--large {
  width: 75%;
}

.modal__body {
  min-height: 100px;
  padding: 15px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
}

.modal__body div {
  text-align: center;
}

.modal__footer {
  min-height: 70px;
  padding: 15px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-open {
  opacity: 1;
}

.modal-open > [class^=modal__size] {
  opacity: 1;
  transform: translateY(0);
}

.visibility {
  display: flex;
}

.hidden {
  display: none;
}

.layout-form__type-company {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  column-gap: 20px;
}

.label-font {
  font-weight: 700;
}

.layout__main {
  margin: 30px 30px 30px 200px;
}

.layout__aside {
  width: 200px;
  height: calc(100vh - 70px);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 70px;
  left: 0;
  z-index: 2;
}

.nav__hash {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 3px solid #194F6E;
  padding: 15px 20px 15px 0;
}

.nav__hash a {
  color: black;
  text-decoration: none;
}

.nav__hash a:visited, .nav__hash a:link {
  color: inherit;
}

.nav__hash svg {
  width: 35px;
  height: 35px;
}

.nav__hash svg:hover {
  cursor: pointer;
}

.layout__between-roads {
  grid-template-columns: 300px 1fr;
}

.layout__ubication {
  grid-template-columns: 400px 1fr;
}

.table__head th, .table__body td {
  padding: 8px 10px;
}

.table {
  background-color: white;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.table__head {
  background-color: #194F6E;
  color: white;
  text-transform: capitalize;
}

.table__body {
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}

.table__body tr:nth-child(odd) {
  background-color: #C4C4C4;
}

.table__body svg {
  width: 20px;
  height: 20px;
}

.table__body svg:hover {
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .table__body {
    font-size: 15px;
  }
}

.table__column-commerce {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.table__option-layout {
  display: flex;
  justify-content: space-between;
}

.table__pagination {
  border: 1px solid #194F6E;
  padding: 2px;
  font-weight: 700;
}

.loader {
  color: #2c2c2c;
  font-size: 6px;
  display: inline-block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

@-webkit-keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

@keyframes load4 {
  0%,
  100% {
    box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
  }
  12.5% {
    box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  25% {
    box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
  }
  37.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  50% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
  }
  62.5% {
    box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
  }
  75% {
    box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
  }
  87.5% {
    box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
  }
}

.aside-container__nav {
  width: 200px;
  height: calc(100vh - 70px);
  position: fixed;
  top: 70px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-container {
  border-right: 3px solid #194F6E;
  width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.nav-container svg {
  width: 35px;
  height: 35px;
}

.nav-container svg:hover {
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .nav-container svg {
    width: 30px;
    height: 30px;
  }
}

.icon__add {
  fill: #194F6E;
  width: 35px;
  height: 35px;
}

.icon__small {
  width: 20px;
  height: 20px;
}

.icon__hover :hover {
  cursor: pointer;
}

.icon__add--position {
  position: absolute;
  left: 200px;
  top: 150px;
  padding: 3px;
  border-bottom: 3px solid #194F6E;
}

.icon__empty-table {
  width: 150px !important;
  height: 150px !important;
}

.table__message-empty {
  font-size: 2.3em;
  font-weight: 700;
}

.icon-active {
  fill: #17A589;
}

.icon-inactive {
  fill: #CB4335;
}

.link {
  text-decoration: none;
  display: inline-block;
}

.margin-child > * {
  margin-right: 30px;
}

.separador__comcepto-pago {
  height: 10px;
  border-bottom: 2px dashed black;
  margin-top: 30px;
}

.commerce__title {
  color: #194F6E;
}

.margin-icon {
  margin-bottom: 50px;
}

.link-razon-social:hover {
  color: #194F6E !important;
  text-decoration: underline;
}

svg.icon__download {
  width: 50px;
  height: 50px;
  stroke: #194F6E;
  fill: #194F6E;
}

svg.icon__download:hover {
  cursor: pointer;
}

@media screen and (max-width: 1400px) {
  .margin-icon {
    margin-bottom: 40px;
  }
}

.tooltip--right {
  align-items: center;
  justify-content: flex-end;
}

.tooltip--left {
  align-items: center;
  justify-content: flex-start;
}

.tooltip__box {
  position: relative;
  display: inline-flex;
}

.tooltip__box:hover .tooltip__text {
  visibility: visible;
  opacity: 1;
}

.tooltip__text {
  visibility: hidden;
  opacity: 0;
  padding: 4px 6px;
  background-color: #2c2c2c;
  color: white;
  position: absolute;
  z-index: 100;
  font-size: 14px;
  font-weight: 900;
  transition: opacity 0.2s linear;
  white-space: nowrap;
}

.tooltip--top {
  justify-content: center;
}

.tooltip--top .tooltip__text {
  top: -30px;
}

.tooltip--bottom {
  justify-content: center;
}

.tooltip--bottom .tooltip__text {
  bottom: -30px;
}

.tooltip--right .tooltip__text {
  transform: translate(102%, 0);
}

.tooltip--left {
  align-items: center;
}

.tooltip--left .tooltip__text {
  transform: translate(-102%, 0);
}

.dropdawn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropdawn ul {
  list-style: none;
  margin-top: 25px;
  background-color: white;
  position: absolute;
  padding: 8px;
  font-size: 14px;
  font-weight: 700;
  border: 2px solid #194F6E;
  z-index: 100;
}

.dropdawn ul li {
  margin-top: 8px;
  display: flex;
  align-items: center;
}

.dropdawn ul li:nth-child(1) {
  margin-top: 0;
}

.dropdawn-inline {
  display: inline-flex !important;
}

.content-unlock {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.icon-unlock svg {
  width: 200px;
  height: 200px;
}

.message-unlock {
  font-size: 30px;
  font-weight: 700;
  margin-top: 50px;
  text-align: center;
}

.details-content {
  margin: 30px 30px 30px 200px;
  font-size: 17px;
}

.details-content .label-detail {
  font-weight: 700;
}

@media screen and (max-width: 1400px) {
  .details-content {
    font-size: 16px;
  }
}

.select-disabled:disabled {
  color: black;
}

.icon-pay-check {
  width: 22px;
  height: 22px;
  fill: black;
}

.icon-size-medium {
  width: 30px;
  height: 30px;
}

.element-hover:hover {
  cursor: pointer;
}

.option-filter {
  background-color: #C4C4C4;
  border: 1px solid #194F6E;
  max-height: 300px;
  overflow: auto;
  padding: 5px;
  position: absolute;
  z-index: 100;
  margin-top: 5px;
  width: 100%;
}

.option-filter li:hover {
  cursor: pointer;
  background-color: #194F6E;
  color: white;
}

.option-filter > :focus {
  outline: none;
  background-color: #194F6E;
  color: white;
}
